/* eslint-disable react/prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TextSection from "../components/text-section";
import TeamMember from "../components/team/team-member";

import teams from "../data/teams.yaml";
import teamPhoto from "../images/team/TiMaScan-team.jpg"
import lumcPhoto from "../images/team/lumc-team.jpg";

import ercLogo from "../images/erc-logo.svg"
import euroflowLogo from "../images/euroflow-logo.svg"
import lumcLogo from "../images/lumc-logo.svg"

const TeamPage = ({ data }) => (
  <Layout>
    <SEO keywords={[`timascan`, `ERC`, `cancer`, `macrophages`]} title="Team" />

    <section>
      <h1 className="text-4xl mb-5">Team</h1>

      <div className="px-0 sm:px-4 md:px-10 mb-10">
        <img src={lumcPhoto} alt="TiMaScan team" className=""/>
      </div>

      <TextSection>
        To realize the goals of the TiMaScan project, the LUMC team works in
        close collaboration with the University of Salamanca, Salamanca, Spain.
      </TextSection>


      <div className="px-0 sm:px-4 md:px-10 mb-10 mt-10">
        <img src={teamPhoto} alt="TiMaScan team" className=""/>
      </div>
      
      {teams.map(team => (
        <div key={team.title} className="mb-20">
          <h2 className="text-3xl mb-4" >
            {team.title}
          </h2>
          {team.sections.map(section => (
            <div key={section.title} className="mb-8">
              <h2 className="text-2xl font-bold mb-8">{section.title}</h2>
              {section.people.map(person => (
                <TeamMember
                  key={person.name}
                  name={person.name}
                  photo={
                    data.allFile.nodes.find(n => n.name == person.photo)
                      .publicURL
                  }
                  roles={person.roles}
                  email={person.email}
                  phone={person.phone}
                />
              ))}
            </div>
          ))}
        </div>
      ))}
    </section>

    <div className="md:flex justify-between my-10">
          <div className="md:flex-none flex justify-center">
            <img
                alt="ERC logo"
                className="w-auto h-20"
                src={ercLogo}
              />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img
              alt="LUMC logo"
              className="h-20 w-auto"
              src={lumcLogo}
            />
          </div>
          <div className="md:flex-none flex justify-center md:m-0 mt-10">
            <img
              alt="EuroFlow logo"
              className="h-20"
              src={euroflowLogo}
            />
          </div>
        </div>
  </Layout>
);

export const query = graphql`
  query MyQuery {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "team" }
      }
    ) {
      nodes {
        name
        publicURL
      }
    }
  }
`;

export default TeamPage;
