import React from "react";
import PropTypes from "prop-types";

function TeamMember({name, photo, roles, email, phone}) {
  return (
    <div className="p-4 sm:p-5 border border-solid border-grey flex rounded items-center mb-5">
      <div className="h-24 w-16 sm:h-56 sm:w-40">
        <img src={photo} alt={"photo " + name} className="h-full w-full object-cover rounded"/>
      </div>
      <div className="ml-5">
        <div className="font-bold">{name}</div>
        <div className="text-xs md:text-sm mt-1 sm:mt-2">
          {roles.map((role) => <p key={role} className="italic">{ role }</p>)}
        </div>
        <div className="text-sm mt-1 sm:mt-2">
          {email && <p>Email: <a href={`mailto:${email}`}>{email}</a></p>}
          {phone && <p>Phone: {phone}</p>}
        </div>
      </div>
    </div>
  )
}

TeamMember.propTypes = {
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string
}

export default TeamMember;